import * as React from 'react';
import { DetailsList, DetailsListLayoutMode, Sticky, ScrollablePane, ConstrainMode, IRenderFunction, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps, TooltipHost, StickyPositionType, ScrollbarVisibility, Selection, SelectionMode, Label, IColumn, SearchBox, Stack, CommandBar, Spinner, SpinnerSize, ICommandBarItemProps, Checkbox, Dialog, DialogFooter, PrimaryButton, DefaultButton, IModalProps, mergeStyles, DialogType, } from '@fluentui/react';
import { getData, renderItemColumn } from "../../utilities/helpers/UserTableHelper"
import { ITenantUser, IUserTableItem } from '../../data-structures/interfaces';
import { RootState } from '../../redux';
import { connect, ConnectedProps } from 'react-redux';
import { LanguageContext } from '../../utilities/LocalizationModule';
import * as UserTableStyle from "../../styles/UserTableStyle"
import EditPanel from "../panels/EditUserPanel";
import EditBulkPanel from "../panels/EditUserBulkPanel";
import { utils, writeFileXLSX } from 'xlsx';
import { LicenseType } from '../../data-structures/enums';
import { UserFilterPanel } from '../panels/UserFilterPanel';
import { setFilterSectionsReset, toggleReload } from '../../redux/modules/filters';
import ManageSubscriptionPanel from '../panels/ManageSubscriptionPanel';
import { deleteUsers } from '../../utilities/helpers/ApiHelper';
import { setUsers } from '../../redux/modules/user';

const dialogStyles = { main: { maxWidth: 450 } };

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );
    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip,
            })}
        </Sticky>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        email: state.user.account.username,
        users: state.user.users,
        filterSections: state.filter?.filterSections,
        toggle: state.filter.toggle,
        user: state.user
    };
};

const mapDispatchToProps = {
    setFilterSectionsReset,
    toggleReload,
    setUsers
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


interface UsersTableProps extends PropsFromRedux {

};

const UsersTable: React.FC<UsersTableProps> = ({
    users,
    filterSections,
    toggle,
    setFilterSectionsReset,
    toggleReload,
    setUsers,
    user
}: UsersTableProps) => {
    const languageStrings = React.useContext(LanguageContext);

    const [items, setItems] = React.useState<IUserTableItem[]>([]);
    const [editItem, setEditItem] = React.useState<ITenantUser>(null);
    const [isPanelOpen, setIsPanelOpen] = React.useState<boolean>(false);
    const [isManageSubscriptionsPanelOpen, setIsManageSubscriptionsPanelOpen] = React.useState<boolean>(false);
    const [isBulkPanelOpen, setIsBulkPanelOpen] = React.useState<boolean>(false);
    const [filteredItems, setFilteredItems] = React.useState<IUserTableItem[]>([]);
    const [filterValue, setFilterValue] = React.useState<string>();
    const [isEditDisabled, setIsEditDisabled] = React.useState<boolean>(true);
    const [isExporting, setIsExporting] = React.useState<boolean>(false);
    const [selectedItems, setSelectedItems] = React.useState<{ tenantId: string, id: number, tenantName: string, displayName: string }[]>([]);
    const [commandBarItems, setCommandBarItems] = React.useState<ICommandBarItemProps[]>([]);
    const [isUserFilterPanelOpen, setIsUserFilterPanelOpen] = React.useState<boolean>(false);
    const [commandBarFarItems, setCommandBarFarItems] = React.useState<ICommandBarItemProps[]>([])
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);

    const deleteDialogContentProps = {
        type: DialogType.normal,
        title: `${languageStrings.Delete} ${editItem ? languageStrings.User : languageStrings.Users}`,
        closeButtonAriaLabel: 'Close',
        subText: languageStrings.DeleteWarningMessageUsers,
    };
    const deleteModalProps: IModalProps = {
        isBlocking: false,
        styles: dialogStyles,
        dragOptions: undefined,
        overlay: {
            className: mergeStyles({
                transition: 'all ease 1s',
            }),
        },
    };

    const _selection = new Selection({
        onSelectionChanged: () => getSelectionDetails()
    });
    const getSelectionDetails = () => {
        setIsEditDisabled(!(_selection.getSelectedCount() > 0));
        const _selectedItems = _selection.getSelection().map(item => {
            return ({
                tenantId: item["tenantId"] ?? "",
                id: item["id"] ?? -1,
                tenantName: item["tenantName"] ?? "",
                displayName: item["displayName"] ?? ""
            })
        });
        setSelectedItems(_selectedItems);
        if ((_selection.getSelectedCount() == 1)) {
            const singleItem: ITenantUser = _selection.getSelection()[0] as ITenantUser;
            setEditItem(singleItem);
        } else {
            setEditItem(null);
        }
    };

    //const [groups, setGroups] = React.useState<IGroup[]>([]);
    const refItems = React.useRef(items);

    React.useEffect(() => {
        updateItems(getData(users, languageStrings));
    }, [users]);

    React.useEffect(() => {
        if (filterSections?.length > 0) {
            if (columnClickedRef.current) {
                setFilteredItems(copyAndSort(doFilterItems(filterValue), columnClickedRef.current.fieldName!, columnClickedRef.current.isSortedDescending));
            } else {
                doFilterItems(filterValue);
            }
        }
        else {
            setFilteredItems(items);
        }
    }, [items]);

    // React.useEffect(() => {
    //     //redo group calculation when filtering
    //     //setGroups(getGroups(filteredItems));
    // }, [filteredItems]);
    React.useEffect(() => {

        const items: ICommandBarItemProps[] = [];
        items.push({
            key: "edit",
            disabled: isEditDisabled,
            iconProps: { iconName: "edit" },
            text: languageStrings.EditDetails,
            onClick: () => editItem != null ? handleOpenPanel(editItem) : setIsBulkPanelOpen(true)
        })

        if (user.isGlobalAdmin || user.isPartner || user.isTenantAdmin) {
            items.push({
                key: "delete",
                disabled: isEditDisabled || isDeleting,
                iconProps: { iconName: "delete" },
                text: languageStrings.Delete,
                onClick: () => setShowDeleteDialog(true)
            })
        }

        if (selectedItems?.length > 1) {
            items.push({
                key: "manage",
                disabled: isEditDisabled,
                iconProps: { iconName: "edit" },
                text: languageStrings.ManageSubscription,
                onClick: () => {
                    setIsManageSubscriptionsPanelOpen(true);
                }
            })
        }

        items.push({
            key: "export",
            disabled: !filteredItems?.length,
            iconProps: { iconName: "ExcelDocument" },
            text: languageStrings.ExportToExcel,
            onClick: () => { exportFile(); },
            onRenderIcon: isExporting ? () => { return <Spinner size={SpinnerSize.small} style={{ marginRight: 4, marginLeft: 4 }} /> } : undefined
        });
        setCommandBarItems(items);

    }, [isEditDisabled, editItem, filteredItems, isExporting, selectedItems, user]);

    React.useEffect(() => {
        const farItems: ICommandBarItemProps[] = [
            {
                key: "Search",
                onRender: onMenuItem_QuickFilterRender
            },
            {
                key: "filter",
                iconProps: { iconName: "Filter" },
                iconOnly: true,
                tooltipHostProps: {
                    content: <span>{languageStrings.Filter}</span>
                },
                style: { marginTop: -5 },
                onClick: () => {
                    setIsUserFilterPanelOpen(true);
                }
            }
        ];

        if (filterSections.some(section => section.items.some(item => item.selected === true)) || filterSections.some(x => (x.daysOffset || x.daysOffset === 0))) {
            farItems.unshift(
                {
                    key: "clearFilter",
                    text: `${languageStrings.ClearFilter}`,
                    iconProps: { iconName: "ClearFilter" },
                    style: { marginTop: -5, marginRight: 11 },
                    onClick: () => {
                        setFilterSectionsReset();
                        toggleReload();
                    }
                }
            )
        }

        setCommandBarFarItems(farItems);
    }, [items, filterSections]);

    React.useEffect(() => {
        if (filterSections?.length > 0) {
            if (columnClickedRef.current) {
                setFilteredItems(copyAndSort(doFilterItems(filterValue), columnClickedRef.current.fieldName!, columnClickedRef.current.isSortedDescending));
            } else {
                doFilterItems(filterValue);
            }
        }
    }, [toggle, filterValue])

    window.dispatchEvent(new Event('resize'));
    const updateItems = (newItems) => {
        refItems.current = newItems;
        setItems(newItems);
    };

    const closeEditPanel = () => {
        if (columnClickedRef.current) {
            setFilteredItems(copyAndSort(doFilterItems(filterValue), columnClickedRef.current.fieldName!, columnClickedRef.current.isSortedDescending));
        } else {
            doFilterItems(filterValue);
        }
        setIsPanelOpen(false);
    };

    const columnClickedRef = React.useRef<{ key: string, fieldName: string, isSorted: boolean, isSortedDescending: boolean }>();

    const _onColumnClick = React.useCallback((_ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
        try {
            const newColumns: IColumn[] = columns.slice();
            const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

            newColumns.forEach((newCol: IColumn) => {
                if (newCol === currColumn) {
                    currColumn.isSortedDescending = !currColumn.isSortedDescending;
                    currColumn.isSorted = true;

                    columnClickedRef.current = {
                        key: currColumn.key,
                        fieldName: currColumn.fieldName,
                        isSorted: currColumn.isSorted,
                        isSortedDescending: currColumn.isSortedDescending
                    };
                } else {
                    newCol.isSorted = false;
                    newCol.isSortedDescending = true;
                }
            });

            const newItems = copyAndSort(filteredItems, currColumn.fieldName!, currColumn.isSortedDescending);
            setColumns(newColumns);
            setFilteredItems(newItems);
            //updateItems(newItems);
        } catch (error) {
            console.error("onColumnClick: ", error);
        }
    }, [filteredItems]);

    function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        try {
            const key = columnKey as keyof T;
            return items.slice(0).sort((a: T, b: T) => {
                return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1)
            });
        } catch (error) {
            console.error("copyAndSort: ", error);
            return items;
        }
    };

    const doFilterItems = (filterText: string): IUserTableItem[] => {
        //Add filtering capabilities for enum
        let filterResult: IUserTableItem[] = [...refItems.current];
        if (filterSections?.length > 0) {
            filterSections.forEach(section => {
                const selectedItems = section.items.filter(x => x.selected);
                if (selectedItems?.length > 0) {
                    if (section.filterField.realFieldName === languageStrings.Tenant) {
                        const selectedValues = selectedItems.map(x => x.value);
                        filterResult = filterResult.filter(item => selectedValues.includes(item.tenantName));
                    }
                    if (section.filterField.realFieldName === languageStrings.Role) {
                        const selectedValues = selectedItems.map(x => x.value);

                        //Partner & admin
                        if (selectedValues.includes(languageStrings.Partner) && selectedValues.includes(languageStrings.TenantAdmin) && !selectedValues.includes(languageStrings.NoRoleAssigned)) {
                            filterResult = filterResult.filter(item => item.isPartner || item.isTenantAdmin);
                        }
                        //Partner
                        else if (selectedValues.includes(languageStrings.Partner) && !selectedValues.includes(languageStrings.TenantAdmin) && !selectedValues.includes(languageStrings.NoRoleAssigned)) {
                            filterResult = filterResult.filter(item => item.isPartner);
                        }
                        //Admin
                        else if (!selectedValues.includes(languageStrings.Partner) && selectedValues.includes(languageStrings.TenantAdmin) && !selectedValues.includes(languageStrings.NoRoleAssigned)) {
                            filterResult = filterResult.filter(item => item.isTenantAdmin);
                        }
                        //No role
                        else if (!selectedValues.includes(languageStrings.Partner) && !selectedValues.includes(languageStrings.TenantAdmin) && selectedValues.includes(languageStrings.NoRoleAssigned)) {
                            filterResult = filterResult.filter(item => !item.isTenantAdmin && !item.isPartner);
                        }
                        //No role & partner
                        else if (selectedValues.includes(languageStrings.Partner) && !selectedValues.includes(languageStrings.TenantAdmin) && selectedValues.includes(languageStrings.NoRoleAssigned)) {
                            filterResult = filterResult.filter(item => (!item.isTenantAdmin && !item.isPartner) || item.isPartner);
                        }
                        //No role & admin
                        else if (!selectedValues.includes(languageStrings.Partner) && selectedValues.includes(languageStrings.TenantAdmin) && selectedValues.includes(languageStrings.NoRoleAssigned)) {
                            filterResult = filterResult.filter(item => (!item.isTenantAdmin && !item.isPartner) || item.isTenantAdmin);
                        }
                    }
                    if (section.filterField.realFieldName === languageStrings.LicenseType) {
                        const selectedValues = selectedItems.map(x => x.value);
                        filterResult = filterResult.filter(item => selectedValues.includes(item.licenseType.toString()));
                    }
                    if (section.filterField.realFieldName === languageStrings.Active) {
                        const selectedValues = selectedItems.map(x => x.value);
                        filterResult = filterResult.filter(item => selectedValues.includes(item.isActivated.toString()));
                    }
                    if (section.filterField.type === "DateTime") {
                        const selectedValues = selectedItems.map(x => x.value);
                        filterResult = filterResult.filter(item => selectedValues.includes(new Date(item[section.filterField.realFieldName]).toLocaleDateString()));
                    }
                }
                else if (section.daysOffset || section.daysOffset === 0) {
                    const currentDate = new Date();
                    const offsetDate = new Date();
                    offsetDate.setDate(currentDate.getDate() + section.daysOffset);

                    filterResult = filterResult.filter(x => section.filterField.realFieldName === "licenseExpiryDate" ? x.licenseType !== LicenseType.Paid : true).filter(user => {
                        let date = new Date(user[section.filterField.realFieldName]);
                        return section.daysOffset > 0 ?
                            date >= currentDate && date <= offsetDate :
                            date <= currentDate && date >= offsetDate;
                    });
                }
            })
        }

        const resultProperties = ["username", "displayName", "tenantName", "role", "licenseType", "licenseExpiryDate", "dateRegistered", "lastActivityDate", "isActivated"];
        // const resultProperties = filterResult.length ? Object.keys(refItems.current[0]) : null;
        filterResult = filterText?.length ?
            filterResult.filter(item => {
                return resultProperties.some(property => {
                    let toSearch = null;
                    property == "licenseType" //handle enum searching
                        ? toSearch = item[property] == 1
                            ? languageStrings.Expired
                            : item[property] === LicenseType.Paid
                                ? languageStrings.Paid
                                : item[property] === LicenseType.Eval
                                    ? languageStrings.Evaluation
                                    : item[property] === LicenseType.Unassigned
                                        ? languageStrings.Unassigned
                                        : languageStrings.Inactive
                        : toSearch = item[property];
                    return (
                        toSearch
                            ?.toString()
                            .toLowerCase()
                            .includes(filterText.toLowerCase()))
                });
            }) :
            filterResult;
        //updateItems(filterResult);
        setFilteredItems(filterResult);

        return filterResult;
    };

    const onSearchFilter = async (filterText: string) => {
        setFilterValue(filterText);
    };
    const onChangeFilter = (event?: React.ChangeEvent<HTMLInputElement>, filterText?: string) => {
        setFilterValue(filterText);
        doFilterItems(filterText);
    };
    const resetFilterValue = () => {
        setFilterValue(null);
        doFilterItems(null);
    };
    const onClearFilter = () => {
        resetFilterValue();
    };
    const onEscapeFilter = () => {
        resetFilterValue();
    };
    const handleOpenPanel = (item: ITenantUser) => {
        setEditItem(item);
        setIsPanelOpen(true);
    };

    const [columns, setColumns] = React.useState<IColumn[]>([]);

    React.useEffect(() => {
        setColumns([{
            key: 'displayName',
            name: languageStrings.DisplayName,
            fieldName: 'displayName',
            data: "string",
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "displayName" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "displayName" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        {
            key: 'username',
            name: languageStrings.Username,
            fieldName: 'username',
            data: "string",
            minWidth: 200,
            maxWidth: 250,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "username" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "username" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        {
            key: 'tenantName',
            name: languageStrings.Tenant,
            fieldName: 'tenantName',
            data: "string",
            minWidth: 100,
            maxWidth: 155,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "tenantName" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "tenantName" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        // {
        //     key: 'status',
        //     name: languageStrings.Status,
        //     fieldName: 'status',
        //     data: "string",
        //     minWidth: 80,
        //     maxWidth: 100,
        //     isResizable: true,
        //     onColumnClick: _onColumnClick
        // },
        {
            key: 'role',
            name: languageStrings.Role,
            fieldName: 'role',
            data: "string",
            minWidth: 80,
            maxWidth: 140,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "role" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "role" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        {
            key: 'licenseType',
            name: languageStrings.LicenseType,
            fieldName: 'licenseType',
            minWidth: 85,
            maxWidth: 120,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "licenseType" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "licenseType" ? columnClickedRef.current.isSortedDescending : false,
            data: "string",
            onColumnClick: _onColumnClick
        },
        {
            key: 'dateRegistered',
            name: languageStrings.DateRegistered,
            fieldName: 'dateRegisteredValue',
            data: "number",
            minWidth: 100,
            maxWidth: 175,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "dateRegistered" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "dateRegistered" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        {
            key: 'expiryDate',
            name: languageStrings.ExpiryDate,
            fieldName: 'licenseExpiryDateValue',
            data: "number",
            minWidth: 80,
            maxWidth: 100,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "expiryDate" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "expiryDate" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        {
            key: 'lastActivityDate',
            name: languageStrings.LastActivity,
            fieldName: 'lastActivityDateValue',
            data: "number",
            minWidth: 80,
            maxWidth: 100,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "lastActivityDate" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "lastActivityDate" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },
        {
            key: 'isActivated',
            name: languageStrings.Active,
            fieldName: 'isActivated',
            data: "number",
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isSorted: columnClickedRef.current && columnClickedRef.current.key === "isActivated" ? columnClickedRef.current.isSorted : false,
            isSortedDescending: columnClickedRef.current && columnClickedRef.current.key === "isActivated" ? columnClickedRef.current.isSortedDescending : false,
            onColumnClick: _onColumnClick
        },])
    }, [filteredItems]);

    const onMenuItem_QuickFilterRender = React.useCallback(() => {
        return <SearchBox
            styles={UserTableStyle.searchBoxStyles}
            disabled={!items?.length}
            placeholder={languageStrings.Search}
            onSearch={onSearchFilter}
            onChange={onChangeFilter}
            onClear={onClearFilter}
            onEscape={onEscapeFilter} />
    }, [items]);

    const exportFile = React.useCallback(async () => {
        setIsExporting(true);
        await new Promise(r => setTimeout(r, 200));
        const ws = utils.json_to_sheet(filteredItems?.map(item => ({
            displayName: item.displayName,
            username: item.username,
            tenant: item.tenantName,
            role: item.role,
            licenseType: item.licenseType === LicenseType.Expired
                ? languageStrings.Expired
                : (item.licenseType === LicenseType.Paid ? languageStrings.Paid : (item.licenseType === LicenseType.Eval ? languageStrings.Evaluation : '-')),
            dateRegistered: item.dateRegisteredValue > -62135596800000 ? item.dateRegistered : undefined,
            expiryDate: item.licenseExpiryDateValue > -62135596800000 ? item.licenseExpiryDate : undefined,
            lastActivityDate: item.lastActivityDateValue > -62135596800000 ? item.lastActivityDate : undefined,
            isActive: item.isActivated ? languageStrings.Yes : languageStrings.No
        })));
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, languageStrings.Users);
        utils.sheet_add_aoa(ws, [[languageStrings.DisplayName, languageStrings.Username, languageStrings.Tenant, languageStrings.Role, languageStrings.LicenseType, languageStrings.DateRegistered, languageStrings.ExpiryDate, languageStrings.LastActivity, languageStrings.Active]], { origin: "A1" });
        const wscols = [
            { wch: 40 },
            { wch: 40 },
            { wch: 25 },
            { wch: 15 },
            { wch: 10 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 10 }
        ];
        ws["!cols"] = wscols;
        writeFileXLSX(wb, `Docubird_${languageStrings.Users}.xlsx`, { compression: true });
        setIsExporting(false);
    }, [filteredItems]);

    const DialogMessage: React.FC = () => {
        return (
            <div>
                <Dialog hidden={!showDeleteDialog} onDismiss={() => setShowDeleteDialog(false)} dialogContentProps={deleteDialogContentProps} modalProps={deleteModalProps}>
                    <DialogFooter>
                        <PrimaryButton
                            disabled={isDeleting}
                            onClick={async () => {
                                setIsDeleting(true);

                                let userIds: number[] = [];
                                if (editItem) {
                                    userIds.push(editItem.id)
                                } else if (selectedItems?.length > 0) {
                                    userIds = selectedItems.map(x => x.id);
                                }

                                if (userIds?.length > 0) {
                                    await deleteUsers({ userIds });
                                }

                                let filteredUsers = users.filter(user => !userIds.includes(user.id));
                                setUsers(filteredUsers);
                                setIsDeleting(false);
                                setShowDeleteDialog(false);
                            }}
                            text={languageStrings.Confirm}
                        >
                            {isDeleting && <Spinner size={SpinnerSize.small} style={{ marginLeft: 5 }} />}
                        </PrimaryButton>
                        <DefaultButton
                            disabled={isDeleting}
                            onClick={() => {
                                setShowDeleteDialog(false);
                            }}
                            text={languageStrings.Cancel}
                        />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    };

    return (
        <>
            <Stack style={{ paddingRight: 40 }}>
                <Stack.Item grow>
                    <Label styles={UserTableStyle.headingStyle}>{languageStrings.Users}</Label>
                    <CommandBar
                        items={commandBarItems}
                        farItems={commandBarFarItems}
                    />
                    <div className={UserTableStyle.tableContainerStyle} id="tableContainer">
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                            <DetailsList
                                items={filteredItems}
                                columns={columns}
                                styles={UserTableStyle.detailListStyles}
                                //groups={groups}
                                selection={_selection}
                                selectionMode={SelectionMode.multiple}
                                layoutMode={DetailsListLayoutMode.justified}
                                constrainMode={ConstrainMode.unconstrained}
                                onRenderItemColumn={(item, index, column) => renderItemColumn(item, index, column, handleOpenPanel, languageStrings)}
                                onRenderDetailsHeader={onRenderDetailsHeader}
                                ariaLabelForSelectionColumn="Toggle selection"
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                checkButtonAriaLabel="select row"
                            />
                        </ScrollablePane>
                    </div>
                    {isUserFilterPanelOpen ?
                        <UserFilterPanel
                            panelIsOpen={isUserFilterPanelOpen}
                            closePanel={() => setIsUserFilterPanelOpen(false)}
                        />
                        : null}
                    {editItem != null ?
                        <EditPanel tenantUser={editItem} closePanel={closeEditPanel} isOpen={isPanelOpen} />
                        : null
                    }
                    {selectedItems != null ?
                        <EditBulkPanel content={selectedItems} setIsOpen={setIsBulkPanelOpen} isOpen={isBulkPanelOpen} />
                        : null
                    }
                    {isManageSubscriptionsPanelOpen && <ManageSubscriptionPanel tenantUser={editItem} selectedUsers={selectedItems} isOpen={isManageSubscriptionsPanelOpen} closePanel={() => setIsManageSubscriptionsPanelOpen(false)} />}
                </Stack.Item>
            </Stack>
            <DialogMessage />
        </>
    )
}

export default connector(UsersTable);