import React from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Stack, Text, Label, PanelType, PrimaryButton, Spinner, SpinnerSize, Dropdown, IDropdownOption, TooltipHost, IconButton, mergeStyles, TooltipDelay } from "@fluentui/react"
import { connect, ConnectedProps } from 'react-redux';
import { setLoading, updateUser } from "../../redux/modules/user"
import { RootState } from '../../redux';
import { assignUsersToSubscription, getTenantSubscriptions, revokeUsersFromSubscription } from '../../utilities/helpers/ApiHelper';
import { AssignSubscriptionRequest, ITenantUser, MollieSubscription } from '../../data-structures/interfaces';
import { SubscriptionStatusEnum, SubscriptionTierEnum } from '../../data-structures/enums';
import { tooltipHeadingStyle, tooltipTextStyle } from '../../styles/PanelStyle';
import { getUserLicenseType } from '../../utilities/helpers/UserLicenseTypeHelper';

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.user.isUserLoading,
        users: state.user.users,
        tenants: state.user.tenants,
        account: state.user.account,
    }
}

const mapDispatchToProps = {
    setLoading,
    updateUser
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface ManageSubscriptionPanelProps extends PropsFromRedux {
    isOpen: boolean,
    closePanel: () => void;
    tenantUser: ITenantUser,
    selectedUsers: { id: number, tenantId: string, tenantName: string, displayName: string }[]
};

const ManageSubscriptionPanel: React.FC<ManageSubscriptionPanelProps> = ({
    isOpen,
    closePanel,
    tenants,
    users,
    tenantUser,
    selectedUsers,
    updateUser
}: ManageSubscriptionPanelProps) => {
    const languageStrings = React.useContext(LanguageContext);
    const [subscriptions, setSubscriptions] = React.useState<MollieSubscription[]>([]);
    const [subscriptionOptions, setSubscriptionOptions] = React.useState<IDropdownOption[]>([]);
    const [selectedSubscription, setSelectedSubscription] = React.useState<IDropdownOption>();

    const [tenantOptions, setTenantOptions] = React.useState<IDropdownOption[]>([]);
    const [selectedTenant, setSelectedTenant] = React.useState<IDropdownOption>();

    const [isSaving, setIsSaving] = React.useState(false);

    React.useEffect(() => {
        if (selectedUsers?.length > 0) {
            const uniqueTenants = selectedUsers.reduce((acc, user) => {
                const foundTenant = acc.find(tenant => tenant.tenantId === user.tenantId);

                if (!foundTenant) {
                    acc.push({ tenantId: user.tenantId, tenantName: user.tenantName });
                }

                return acc;
            }, []);

            const dropdownOptions = uniqueTenants.map(tenant => ({
                key: tenant.tenantId,
                text: tenant.tenantName
            }));

            setTenantOptions(dropdownOptions);
        }
    }, [selectedUsers]);

    React.useEffect(() => {
        if (tenantOptions.length > 0 && !selectedTenant) {
            setSelectedTenant(tenantOptions[0])
        }
    }, [tenantOptions]);

    React.useEffect(() => {
        (async () => {
            const tenant = tenants.find(x => x.tenantId === selectedTenant.key);
            const subscriptions = await getTenantSubscriptions(tenant.id, languageStrings);

            setSubscriptions(subscriptions);
            const tenantUsers = selectedUsers.filter(x => x.tenantId === selectedTenant.key);

            const tenantSubs: IDropdownOption[] = subscriptions.filter(x => x.status !== SubscriptionStatusEnum.Canceled).sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()).map(sub => {
                let licenseText;

                if (sub.status === SubscriptionStatusEnum.Canceled) {
                    licenseText = `(${languageStrings.Canceled})`;
                } else if (sub.method === "invoice" || sub.method === "invoice_dynamic") {
                    licenseText = `(${languageStrings.Invoice})`;
                } else if (sub.tier === SubscriptionTierEnum.Trial) {
                    licenseText = `(${new Date(sub.expiryDate).toLocaleDateString()})`;
                } else {
                    licenseText = `(${sub.availableLicenses} ${languageStrings.Available.toLocaleLowerCase()})`;
                }

                const option: IDropdownOption = {

                    key: sub.id,
                    text: `${sub.description} ${licenseText}`,
                    data: sub,
                    disabled: (sub.availableLicenses === 0 || tenantUsers?.length > sub.availableLicenses || sub.status === SubscriptionStatusEnum.Canceled)
                }

                return option;
            });

            tenantSubs.unshift({
                key: "revokeUser",
                text: "",
            })

            setSubscriptionOptions(tenantSubs);

            if (tenantUsers.length > 1) {
                setSelectedSubscription(tenantSubs[0]);
            } else if (tenantUsers.length === 1) {
                const tenantUser = users.find(x => x.id === tenantUsers[0].id);

                if (tenantUser?.subscriptionId) {
                    const sub = tenantSubs.find(x => x.key === tenantUser?.subscriptionId)
                    if (sub) {
                        setSelectedSubscription(sub);
                    }
                }
            }
        })();
    }, [selectedTenant]);

    const onSubscriptionChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedSubscription(item);
    };

    const onTenantChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedTenant(item);
    };

    const TooltipContent = () => {
        return <>
            <Label styles={tooltipHeadingStyle} style={{ paddingBottom: 0 }}>{languageStrings.Users}</Label>
            <ul className={mergeStyles({ paddingLeft: 25 })}>
                <Stack>
                    {[...selectedUsers.filter(x => x.tenantName === selectedTenant.text)].sort().map((u, i) =>
                        <li key={i}><Label styles={tooltipTextStyle}>
                            {u.displayName}
                        </Label></li>)}
                </Stack>
            </ul>
        </>
    }

    return (
        <Panel
            headerText={languageStrings.ManageSubscription}
            isOpen={isOpen}
            isLightDismiss
            onDismiss={() => {
                closePanel();
            }}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"500px"}
        >
            <Stack tokens={{ childrenGap: 20 }} style={{ marginTop: 20 }}>
                {tenantOptions?.length > 0 && <Dropdown
                    label={languageStrings.Tenant}
                    selectedKey={selectedTenant?.key}
                    options={tenantOptions}
                    onChange={onTenantChange}
                />}
                <Stack horizontal verticalAlign='center'>
                    {selectedTenant && <Text>{selectedUsers.filter(x => x.tenantId === selectedTenant.key).length} user{selectedUsers.filter(x => x.tenantId === selectedTenant.key).length == 1 ? '' : 's'} selected from {selectedTenant.text}</Text>}
                    <TooltipHost
                        content={selectedTenant && <TooltipContent />}
                        calloutProps={{ gapSpace: 0, calloutWidth: 200 }}
                        styles={{
                            root: {
                                display: 'inline-block',
                            }
                        }}
                        delay={TooltipDelay.zero}
                    >
                        <IconButton iconProps={{
                            iconName: 'Info'
                        }} />
                    </TooltipHost>
                </Stack>
                {subscriptionOptions?.length > 1 && <Dropdown
                    placeholder={languageStrings.SelectSubscription}
                    options={subscriptionOptions}
                    onChange={onSubscriptionChange}
                    selectedKey={selectedSubscription?.key}
                />}
                {subscriptionOptions?.length === 1 &&
                    <Stack horizontalAlign='center'>
                        <Label>{languageStrings.NoSubscriptionsFound}</Label>
                    </Stack>}
                <Stack horizontalAlign='end'>
                    <PrimaryButton
                        disabled={!selectedSubscription || isSaving}
                        text='Save'
                        onClick={async () => {
                            setIsSaving(true);
                            try {
                                const tenant = tenants.find(x => x.tenantId === selectedTenant.key);
                                const gridTenantUsers = selectedUsers.filter(x => x.tenantId === selectedTenant.key);

                                const request: AssignSubscriptionRequest = {
                                    tenantId: tenant.id,
                                    subscriptionId: selectedSubscription.key === "revoke" ? null : selectedSubscription.key as number,
                                    userIds: gridTenantUsers.map(x => x.id)
                                }

                                let updatedUserIdList: number[];
                                if (selectedSubscription.key === "revoke") {
                                    await revokeUsersFromSubscription(request);
                                    updatedUserIdList = request.userIds;
                                } else {
                                    updatedUserIdList = await assignUsersToSubscription(request);
                                }

                                if (Array.isArray(updatedUserIdList) && updatedUserIdList.length > 0) {
                                    const tenantUsers: ITenantUser[] = users.filter(user => updatedUserIdList.includes(user.id));
                                    const subscription = subscriptions.find(x => x.id === request.subscriptionId);
                                    if (subscription) {
                                        tenantUsers.forEach(user => {
                                            const newTenantUser = { ...user };
                                            newTenantUser.subscriptionId = subscription.id;
                                            newTenantUser.licenseType = getUserLicenseType(subscription);
                                            updateUser(newTenantUser, true);
                                        });
                                    }
                                }
                            } catch (error) {
                                console.error(error);
                            }
                            setIsSaving(false);
                            closePanel();
                        }}
                    >
                        {isSaving && <Spinner size={SpinnerSize.small} style={{ paddingLeft: 10 }} />}
                    </PrimaryButton>
                </Stack>
            </Stack>

        </Panel>
    )
}

export default connector(ManageSubscriptionPanel);