import { IButtonStyles, IconButton, TextField } from "@fluentui/react";
import React from 'react';
import { ITenant } from "../../../data-structures/interfaces";

const revealButtonStyles: IButtonStyles = {
	root: {
		backgroundColor: 'transparent',
		color: '#323130',
	},
	rootHovered: {
		backgroundColor: 'transparent',
		color: '#323130',
	},
	rootPressed: {
		backgroundColor: 'transparent',
	},
}

interface ISecuredInputProps {
	inputLabel: string;
	propertyValue: string;
	propertyName: string;
	setNewTenant: (value: React.SetStateAction<ITenant>) => void;
}

export const SecuredInput: React.FC<ISecuredInputProps> = (props: ISecuredInputProps) => {
	const [isValueVisible, setIsValueVisible] = React.useState<boolean>(false);

	const onRevealValueClick = () => {
		setIsValueVisible(prev => !prev);
	};

	const renderSuffix = () => {
		return (
			<IconButton
				iconProps={{ iconName: isValueVisible ? "Hide" : "RedEye" }}
				ariaLabel={isValueVisible ? "Hide value" : "Show value"}
				onClick={onRevealValueClick}
				styles={revealButtonStyles}
				tabIndex={-1}
			/>
		);
	};

	return (
		<TextField
			label={props.inputLabel}
			styles={{
				suffix: {
					backgroundColor: "white"
				}
			}}
			type={isValueVisible ? "text" : "password"}
			value={props.propertyValue}
			onChange={(_, newValue?: string) => props.setNewTenant(prevTenant => ({ ...prevTenant, [props.propertyName]: newValue }))}
			onRenderSuffix={renderSuffix}
			canRevealPassword={false}
		/>
	);
};
