
import { DocumentCard, DocumentCardDetails, Stack, Text } from '@fluentui/react';
import React from 'react';
import { stackStyles, stackTokens, textMedium } from '../styles/MessageScreenStyle';
import docubirdIcon from "../assets/logo.png";

interface MessageScreenProps {
    text: string,
    actionButton?: JSX.Element;
};

const MessageScreen: React.FC<MessageScreenProps> = ({
    text,
    actionButton
}: MessageScreenProps) => {
    return (
        <Stack style={{ height: "100vh" }} verticalAlign="center" horizontalAlign="center">
            <DocumentCard styles={{
                root: {
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                    width: "30%",
                    minWidth: "265px",
                    maxWidth: "500px"
                }
            }}>
                <DocumentCardDetails>
                    <Stack styles={stackStyles} tokens={stackTokens}>
                        {<Stack.Item>
                            <img style={{ width: "220px", height: "auto", margin: "auto" }} src={docubirdIcon} alt="Docubird" title="Docubird" />
                        </Stack.Item>}
                        <Stack.Item>
                            <Text className={textMedium}>
                                Docubird Admin Portal
                            </Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="medium">{text}</Text>
                        </Stack.Item>
                        <Stack.Item>
                            {actionButton}
                        </Stack.Item>
                    </Stack>
                </DocumentCardDetails>
            </DocumentCard>
        </Stack>
    )
}

export default MessageScreen;